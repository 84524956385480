import React from "react"
import logo from "../../static/logo-white.png"
import Layout from "../components/layout"
import styles from "./index.module.scss"

class Index extends React.Component {
  render() {
    return (
      <Layout title="Home | Good Behavior">
        <div className={styles.index}>
          <img className={styles.logo} src={logo} alt="Good Behavior logo" />
          <p>Coming soon.</p>
        </div>
      </Layout>
    )
  }
}

export default Index
